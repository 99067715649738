import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Loico.us, LLC</h1>
        <h3>Financial Data Engineering</h3>
        <ul>
          <li class="cour">
            Limited data publicly available at{" "}
            <a href="https://findat.us" target="_blank">
              findat.us
            </a>
          </li>
        </ul>
        <h3>contact: loi(at)loico.us</h3>
      </header>
    </div>
  );
}

export default App;
